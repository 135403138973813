<template>
    <div class="max-w-lg mx-auto">
        <div class="flex flex-row justify-between items-center h-24 p-5">
            <p class="font-poppins font-bold heading-1 text-2xl">
                <span class="font-poppins heading-1 font-bold  text-2xl"> Hey </span>
                <span class="font-poppins capitalize font-bold text-primary">{{
                    firstname
                }}</span>
                <span v-if="user.tutor" class="body font-normal text-base leading-5 text-gray-500">
                    • Tutor</span>
                <span v-if="!user.tutor" class="body font-normal text-base leading-5 text-gray-500">
                    • Student</span>
            </p>
            <Button v-if="!!buy_card" color="primary" shape="ghost" @click="$event=>this.$router.push('/card-info')">Learn More</Button>
        </div>   
        <hr />
        <hr />
<div>
    <slot></slot>
</div>   
    </div>
</template>

<script>
import Button from "../Feed/Button.vue";
export default {
    data() {
        return {
            user:null,
            firstname: "",
            lastname : "",
            university : "",
            
        }
    },
    props: {
        buy_card: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        Button,
    },
    created() {
       this.setUser();
        console.log(this.user)
    },
    methods: {
        setUser() {
            this.user = this.$store.state.user;
            // splitting name into firstname and lastname
            const name = this.user.name.split(" ");
            this.firstname = name[0];
            name.shift();
            this.lastname = name.join(" ");
            this.lastname = this.lastname.replace(/\s+/g, " ").trim();
            this.university = this.user.university.abbrev;
            
        },
    }
}
</script>

<style lang="scss" scoped></style>