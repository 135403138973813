<template>
  <qrcode-vue class="mx-auto" :value="discountURL" :size="size" level="H" />
</template>
<script>
import QrcodeVue from "qrcode.vue";

export default {
  props: {
    discount_id: String,
    size: {
      type: Number,
      default: 250,
    },
  },
  data() {
    return {
      discountURL:
        "https://students.tutoruu.com/discounts/" +
        this.discount_id +
        "/use/" +
        this.$store.state.user._id,
    };
  },
  components: {
    QrcodeVue,
  },
};
</script>
