<template>
  <teleport to="body">
    <div @click="close" style="z-index: 998!important;" class="fixed  inset-0 bg-black transition duration-500 ease-out"
      :class="{ 'bg-opacity-60': open, 'bg-opacity-0 pointer-events-none': !open }" />

    <div id="sheet">
      <transition name="slide-up" appear>
        <div style="z-index: 999!important;" class="rounded-t-3xl fixed inset-x-0 p-8 bottom-0 bg-white" v-if="open">
          <slot></slot>
        </div>
      </transition>
    </div>
  </teleport>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      open: this.active,
    };
  },
  methods: {
    close() {
      this.open = false;
      this.$emit("close");
    },
  },
  emits: ["close"]
};
</script>