<template>
    <div class="flex flex-row justify-between shadow-lg rounded-lg p-5 border">
        <div class=" flex flex-row">
            <img class="rounded-full border-gray-100 shadow-sm w-12 h-12 mr-2 text-xs" :src="discount.vendor.logo"
                :alt="`${discount.vendor.name} Logo`" />
            <div class="flex flex-col">
                <p class=" font-poppins font-medium text-base leading-6">{{ discount.vendor.name }}</p>
                <p class=" font-poppins font-normal text-base leading-6">{{ discountText }}</p>
            </div>
        </div>
        <svg @click="() => this.$emit('clicked')" width="44" height="45" viewBox="0 0 44 45" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <rect y="0.111572" width="44" height="44" rx="22" fill="#FE8303" />
            <path
                d="M23.7242 15.8356C23.2028 16.3569 23.2028 17.1996 23.7242 17.7209L26.7815 20.7782H16.6668C15.9308 20.7782 15.3335 21.3756 15.3335 22.1116C15.3335 22.8476 15.9308 23.4449 16.6668 23.4449H26.7815L23.7242 26.5022C23.2028 27.0236 23.2028 27.8662 23.7242 28.3876C23.9842 28.6476 24.3255 28.7782 24.6668 28.7782C25.0082 28.7782 25.3495 28.6476 25.6095 28.3876L31.8855 22.1116L25.6095 15.8356C25.0882 15.3142 24.2455 15.3142 23.7242 15.8356Z"
                fill="white" />
        </svg>

    </div>
</template>

<script>
import Avatar from "./Avatar.vue"
export default {
    components: { Avatar },
    computed: {
        discountText() {
            return this.discount.discountValue + (this.discount.discountType === "Percentage" ? "%" : "EGP") + " Off"
        },
    },
    props: {
        discount: {
            type: Object,
            required: true
        }
    },
    emits: ["clicked"]
}
</script>
