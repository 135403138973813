<template>
  <page-layout title="Feed" hideBack logo>
    <mainLayout :buy_card="!cardOwner">
      <div class="w-full h-full">
        <div
          :key="updateTab - 1"
          class="h-16 py-2 flex bov scrollbar-hide space-x-2 scroll scroll-smooth overflow-x-auto pl-2"
        >
          <FilterTag
            v-for="(category, i) in categories"
            :key="i"
            class="whitespace-nowrap uppercase"
            @click="filterCategory(category)"
            :active="activeCategory === category"
          >
            {{ category }}
          </FilterTag>
        </div>
        <div
          v-if="discounts.length > 0"
          :key="updateTab + 1"
          class="flex flex-col space-y-3 px-3"
        >
          <div v-for="discount in filteredDiscounts" class="w-full">
            <DiscountCard
              :key="discount._id"
              :discount="discount"
              @clicked="() => fireDiscount(discount)"
            />
          </div>
        </div>

        <div v-if="!cardOwner" class="fixed inset-x-0 bottom-0 m-4">
          <Button
            color="primary"
            shape="full"
            @click="order"
            class="mt-10 max-w-md mx-auto lg:my-6"
            >Purchase Card</Button
          >
        </div>

        <BottomSheet v-if="!!previewed" :active="sheet" :key="updateKey">
          <div class="flex flex-col items-center text-center space-y-3">
            <img :src="previewed.vendor.logo" alt="" class="w-32" />
            <h2 class="font-heading text-xl font-bold">
              {{ previewed.vendor.name }}
            </h2>
            <div
              v-if="!previewed.redeemed && previewed.vendor.description"
              v-html="renderDescription(previewed)"
              class="space-y-3"
            />
            <transition name="scale-up">
              <div class="py-3 w-full" v-if="previewed.redeemed">
                <Code :discount_id="previewed.id" v-if="!previewed.promocode" />
                <div
                  v-else
                  @click="copy(previewed.promocode)"
                  class="w-full p-6 rounded-xl bg-orange-tint text-orange relative"
                >
                  <p class="font-heading font-bold text-xl text-center">
                    {{ previewed.promocode }}
                  </p>
                  <div
                    class="w-12 h-12 flex justify-center rounded-full items-center bg-orange text-white absolute right-4 top-1/2"
                    style="transform: translateY(-50%)"
                  >
                    <Icon :name="copied ? 'tick' : 'clipboard'" size="x" />
                  </div>
                </div>
              </div>
            </transition>
            <h2 v-if="!cardOwner" class="text-primary font-xl">
              {{ getDiscountText(previewed) }}
            </h2>
            <Button
              v-else-if="previewed.redeemed"
              color="primary"
              shape="ghost"
              @click="
                previewed.redeemed = false;
                previewed = null;
              "
              >Confirm</Button
            >
            <Button v-else color="primary" shape="full" @click="useDiscount"
              >Redeem {{ getDiscountText(previewed) }}</Button
            >
          </div>
        </BottomSheet>
        <BottomSheet :active="cardSheet" :key="updateKeyCard">
          <div class="flex flex-col items-center text-center space-y-3">
            <img
              :src="require('@/assets/discount-card.png')"
              alt=""
              class="w-50 rounded-2xl"
            />
            <h2 class="font-heading text-xl font-bold">
              Student life just got sweeter with our Ruu+ discount card…
            </h2>
            <p>Order your card now & save big on your favorite stores!</p>
            <div class="flex flex-row items-center gap-x-3">
              <Button
                color="secondary"
                @click="
                  cardSheet = false;
                  updateKeyCard++;
                  learn_more();
                "
              >
                Learn More</Button
              >
              <Button
                color="primary"
                @click="
                  cardSheet = false;
                  updateKeyCard++;
                  order();
                "
              >
                GET YOUR CARD</Button
              >
            </div>
          </div>
        </BottomSheet>
      </div>
    </mainLayout>
  </page-layout>
</template>

<script>
import mainLayout from '../../components/layouts/mainLayout.vue';
import Loading from '../../components/Loading.vue';
import pageLayout from '../../components/base/pageLayout.vue';
import DiscountCard from '../../components/Feed/DiscountCard.vue';
import FilterTag from '../../components/Feed/FilterTag.vue';
import BottomSheet from '../../components/Feed/BottomSheet.vue';
import Button from '../../components/Feed/Button.vue';
import Code from '../../components/Discount/Code.vue';
import Icon from '../../components/Icon.vue';
import { getDiscounts } from '../../helpers/cms';
import mixpanel from 'mixpanel-browser';

export default {
  components: {
    FilterTag,
    DiscountCard,
    BottomSheet,
    Button,
    pageLayout,
    mainLayout,
    Loading,
    Code,
    Icon,
  },
  data() {
    return {
      discounts: [],
      filteredDiscounts: [],
      previewed: null,
      sheet: false,
      updateKey: 0,
      cardSheet: !this.$store.state.user?.discountCard,
      cardOwner: this.$store.state.user?.discountCard,
      updateKeyCard: 0,
      activeCategory: '',
      updateTab: 0,
      categories: [],
      copied: false,
      user: this.$store.state.user,
    };
  },
  created() {
    getDiscounts().then((discounts) => {
      this.discounts = discounts.map((item) => {
        item.vendor.category = item.vendor.category
          .replaceAll('_', ' ')
          .replace('and', '&');
        item.vendor.logo = item.vendor.logo.url;
        if (!item.vendor.website.includes('https'))
          item.vendor.website = 'https://' + item.vendor.website;
        return item;
      });
      this.filterCategory('All');
      this.categories = [
        ...new Set([
          'All',
          'E-GEMZ',
          ...this.discounts.map((item) => item.vendor.category).flat(),
        ]),
      ];

      mixpanel.track('Discounts Viewed', {
        user: this.user,
      });
    });
  },
  methods: {
    fireDiscount(discount) {
      this.copied = false;
      this.previewed = discount;
      this.sheet = true;
      this.updateKey++;

      mixpanel.track('Discount Viewed', {
        user: this.user,
        discount,
      });
    },
    useDiscount() {
      if (!!this.$store.state.user?.discountCard) {
        mixpanel.track('Discount Redeemed', {
          user: this.user,
          discount: this.previewed,
        });

        return (this.previewed.redeemed = true);
      }
      this.previewed = null;
      this.order();
    },
    getDiscountText(discount) {
      return `${discount.discountValue} ${
        discount.discountType === 'Percentage' ? '%' : 'EGP'
      } Off`;
    },
    copy(text) {
      this.copied = true;
      setTimeout(() => {
        this.copied = false;
      }, 4000);

      navigator.clipboard.writeText(text);

      mixpanel.track('Promocode Copied', {
        user: this.user,
        discount: this.previewed,
      });
    },
    filterCategory(category) {
      if (category === this.activeCategory) return;

      mixpanel.track('Categories Filtered', {
        user: this.user,
        previous_category: this.activeCategory,
        new_category: category,
      });

      if (category === 'E-GEMZ')
        this.filteredDiscounts = this.discounts.filter(
          (discount) => discount.vendor.method === 'EGEMZ'
        );
      else
        this.filteredDiscounts = this.discounts.filter(
          (discount) =>
            discount.vendor.category.includes(category) || category === 'All'
        );

      this.activeCategory = category;
      this.updateTab++;
    },
    renderDescription(discount) {
      let desc = discount.additional_info
        ? `${discount.additional_info}\n\n`
        : '';
      const website = discount.vendor.website;
      const vendor_desc = discount.vendor.description;

      if (discount.vendor.method === 'Method_1')
        desc += `How to use: show the QR code or RuuPlus card to ${discount.vendor.name} (click redeem to view the QR code)`;
      if (discount.vendor.method === 'Method_2')
        desc += `How to use: order through ${discount.vendor.name}'s instagram direct messages and send a picture of the QR code  (click redeem to view the QR code)`;
      if (
        discount.vendor.method === 'Method_3' ||
        discount.vendor.method === 'EGEMZ'
      )
        desc += `How to use: use the promocode when ordering from ${website} (click redeem to view the promocode)`;

      const paragraph_classes = `class="font-heading text-sm font-light pb-1 whitespace-pre-line"`;
      const link_opening_tag = `<a class="text-orange underline underline-offset-2 hover:shadow-lg" href="${website}" target="_blank">`;

      return `<p ${paragraph_classes}>${
        vendor_desc === ' '
          ? ''
          : vendor_desc.replaceAll('\\n', '<br />') + '<br />'
      } ${link_opening_tag}Check them out</a></p>
                        <p ${paragraph_classes}>${desc.replace(
        website,
        `${link_opening_tag}${website.replace('https://', '')}</a>`
      )}</p>`;
    },
    order() {
      mixpanel.track('Card Order Started', {
        user: this.user,
      });

      this.$router.push('/pickupLocation');
    },
    learn_more() {
      mixpanel.track('Card Info Viewed', {
        user: this.user,
      });

      this.$router.push('/card-info');
    },
  },
};
</script>
<style scoped>
.box {
  flex-basis: 1.75;
}
</style>
