import dotenv from 'dotenv';

dotenv.config();

const url = process.env["VUE_APP_HYGRAPH_API_URL"];
const bearer_token = `Bearer ${process.env["VUE_APP_HYGRAPH_API_TOKEN"]}`;

async function sendRequest(query, isMutation=false) {
    const res = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'authorization': bearer_token,
        },
        body: JSON.stringify({ query })
    });

    if(isMutation) return res

    const { data, errors } = await res.json();

    if (Array.isArray(errors)) errors.forEach((error) => console.log(error));

    return data;
}


export async function getDiscounts() {
    return (await sendRequest(`
    query {
        discounts(orderBy: position_ASC, where: {vendor: {archived: false}}, last: 100) {
            additionalInfo
            discountType
            discountValue
            id
            promocode
            vendor {
                archived
                category
                description
                id
                logo {
                    url(transformation: {document: {output: {format: png}}})
                }
                method
                name
                website
            }
        }
    }
    `)).discounts;
}

export async function getDiscount(id) {
    return (await sendRequest(`
    query {
        discount(where: {id: "${id}"    }) {
            additionalInfo
            discountType
            discountValue
            id
            promocode
            vendor {
                archived
                category
                description
                id
                method
                name
                website
                logo {
                    url(transformation: {
                        document: {
                            output: {format: png}
                        },
                        image: {
                            resize: {
                                height: 225,
                                width: 225,
                            }
                        }
                    })
                }
            }
        }
    }
    `)).discount;
}

export async function createDiscountUse(id, email) {
    return sendRequest(`
        mutation {
            createDiscountUse(data: {
                discount: {
                    connect: {
                            id: "${id}"
                        }}, 
                email: "${email}"
            }) {
                id
            }
        }
    `, true);
}

