<template>
  <span :class="`transition duration-300 ease-out typcn typcn-${name} ${size && `typcn-${size}`}`" />
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true
    },
    size: {
      type: String,
      default: null,
      enum: ['lg', 'x', '2x', '3x', '4x']
    }
  }
}
</script>
